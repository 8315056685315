<template>
    <div class="library">
        <transition name="fade-transform" mode="out-in">
            <router-view :key="$route.fullPath"></router-view>
        </transition>
    </div>
</template>
<script>
export default {
    components: {},
}
</script>